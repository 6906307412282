import { Loader } from '@mantine/core';
import { Icon, variants } from '@uag/react-core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentType } from 'api/models';
import { humanFileSize } from 'utils/fileSize';

type RequestAttachmentProps = {
    fileName?: string | null;
    id: string;
    attachmentType: keyof typeof AttachmentType;
    fileSizeBytes?: number | null;
    onClick: () => Promise<void>;
};
export const RequestAttachment = ({ fileName, id, attachmentType, fileSizeBytes, onClick }: RequestAttachmentProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true);
        onClick().finally(() => setIsLoading(false));
    };

    return (
        <div key={id} className="w-full border-[1px] border-solid border-border-default px-3 py-2 ">
            <div className="flex w-full gap-2 content-center cursor-pointer select-none" onClick={handleClick}>
                <div className="flex-grow truncate">
                    <p className="text-base truncate">{fileName}</p>
                    <p className="text-sm text-text-light">
                        {t(attachmentType)} {fileSizeBytes && `- ${humanFileSize(fileSizeBytes)}`}
                    </p>
                </div>
                {isLoading && <Loader className="w-6 h-6 border-2" variant={variants.loader.loadingAnimation} />}
                {!isLoading && <Icon className="mt-0.5" icon="file_download" size="2xl" />}
            </div>
        </div>
    );
};
