/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GetIndustrialSectorsResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Returns a list of Industrial sectors and their translations, based on the Accept-Language header.
 */
export const getIndustrialSectors = (
    
 options?: SecondParameter<typeof customMutator>,signal?: AbortSignal
) => {
      
      
      return customMutator<GetIndustrialSectorsResponse>(
      {url: `/industrial-sectors`, method: 'GET', signal
    },
      options);
    }
  

export const getGetIndustrialSectorsQueryKey = () => {
    return [`/industrial-sectors`] as const;
    }

    
export const getGetIndustrialSectorsQueryOptions = <TData = Awaited<ReturnType<typeof getIndustrialSectors>>, TError = ErrorType<ProblemDetails>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIndustrialSectors>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIndustrialSectorsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIndustrialSectors>>> = ({ signal }) => getIndustrialSectors(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getIndustrialSectors>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIndustrialSectorsQueryResult = NonNullable<Awaited<ReturnType<typeof getIndustrialSectors>>>
export type GetIndustrialSectorsQueryError = ErrorType<ProblemDetails>


export function useGetIndustrialSectors<TData = Awaited<ReturnType<typeof getIndustrialSectors>>, TError = ErrorType<ProblemDetails>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIndustrialSectors>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIndustrialSectors>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetIndustrialSectors<TData = Awaited<ReturnType<typeof getIndustrialSectors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIndustrialSectors>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getIndustrialSectors>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetIndustrialSectors<TData = Awaited<ReturnType<typeof getIndustrialSectors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIndustrialSectors>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetIndustrialSectors<TData = Awaited<ReturnType<typeof getIndustrialSectors>>, TError = ErrorType<ProblemDetails>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getIndustrialSectors>>, TError, TData>>, request?: SecondParameter<typeof customMutator>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetIndustrialSectorsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



