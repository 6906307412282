import { useTranslation } from 'react-i18next';

import { OnboardingRequestDetailModel } from 'api/models';
import { Information, InformationSection } from 'components/Information/Information';

type Props = {
    request: OnboardingRequestDetailModel;
};

export const CompanyInformation = ({ request }: Props) => {
    const { t } = useTranslation();

    if (!request) {
        return null;
    }

    const company = request.companyData;

    return (
        <Information>
            {company.companyName && (
                <InformationSection title={company.companyName}>
                    {company.additionalCompanyName && <p>{company.additionalCompanyName}</p>}
                </InformationSection>
            )}
            <InformationSection title={t('address')}>
                <p>
                    {company.mainAddress?.street} {company.mainAddress?.houseNumber}
                </p>
                <p>
                    {company.mainAddress?.zipCode} {company.mainAddress?.city}
                </p>
                <p>{company.mainAddress?.countryConfiguration?.name}</p>
            </InformationSection>
            <InformationSection title={t('contactInformation')}>
                <p>{t('generalOfficeMailContent', { generalOfficeMail: company.email })}</p>
                <p>{t('phoneNumberContent', { phoneNumber: company.phone })}</p>
            </InformationSection>
            <InformationSection title={t('financialInformation')}>
                <p>{t('uidNumberContent', { uidNumber: company.taxNr })}</p>
            </InformationSection>
            <InformationSection title={t('keyUser')}>
                <p>
                    {t('technicalAdministratorContent', {
                        technicalAdministrator: request?.administrators && request.administrators[0]?.model.email
                    })}
                </p>
                <p>
                    {t('contractSignatoryContent', {
                        contractSignatory: request?.signers && request.signers[0]?.model.email
                    })}
                </p>
            </InformationSection>
        </Information>
    );
};
