import Hotjar from '@hotjar/browser';
import { Notifications } from '@mantine/notifications';
import { getCustomThemeOverrides } from '@shared/theming/getCustomThemeOverrides';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
    AuthProvider,
    DefaultErrorBoundary,
    MantineOverrideProvider,
    Theme,
    ThemeProvider,
    enableUserTracking
} from '@uag/react-core';
import { t } from 'i18next';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { configurationIdentityServer } from 'app/configurationIdentityServer';
import { applicationInsightsReactPlugin } from 'utils/applicationInsights';
import { AppInsightsContextProvider } from 'utils/ApplicationInsightsContextProvider';
import { AppRoutes } from './AppRoutes';
import './App.css';
import '../i18n';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: { retry: false, refetchOnWindowFocus: false, refetchOnReconnect: false, refetchOnMount: false }
    }
});

enableUserTracking();

export const App = () => {
    if (import.meta.env.VITE_APP_HOTJAR_SITEID) {
        Hotjar.init(import.meta.env.VITE_APP_HOTJAR_SITEID, 6);
    }

    const customThemeOverrides = getCustomThemeOverrides();
    useEffect(() => {
        document.title = t('pageTitle');
    });

    return (
        <BrowserRouter>
            <ThemeProvider theme={Theme.Doka}>
                <MantineOverrideProvider customThemeOverrides={customThemeOverrides}>
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider
                            authority={import.meta.env.VITE_APP_OIDC_AUTHORITY}
                            configuration={configurationIdentityServer}
                        >
                            <AppInsightsContextProvider>
                                <DefaultErrorBoundary reactPlugin={applicationInsightsReactPlugin}>
                                    <Notifications />
                                    <AppRoutes />
                                </DefaultErrorBoundary>
                            </AppInsightsContextProvider>
                        </AuthProvider>
                    </QueryClientProvider>
                </MantineOverrideProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
};
