import { useDisclosure } from '@mantine/hooks';
import { welcomeApiClient } from '@shared/api/customMutator';
import { StepActionModel, StepActionType, StepProgress } from '@shared/api/models';
import { getDownloadContractFilesZipContentQueryKey } from '@shared/api/queries/contracts/contracts';
import { getGetStepsQueryKey } from '@shared/api/queries/steps/steps';
import { downloadFile } from '@shared/utils';
import { TrackingEvents } from '@shared/utils/trackingsEvents';
import { useQueryClient } from '@tanstack/react-query';
import { sendTrackingEvent, Timeline } from '@uag/react-core';
import { getExceptionDetails, SymbolCodepoints, toastNotifications } from '@uag/react-core/dist/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { StepActionModal } from 'app/components/StepActionModal';

const POLLING_INTERVAL = 5000;

type Props = {
    stepAction: StepActionModel;
    stepProgress: StepProgress;
};

const getActionIcon = (stepActionType: StepActionType): SymbolCodepoints => {
    switch (stepActionType) {
        case StepActionType.FileUpload:
            return 'upload';
        case StepActionType.FileDownload:
            return 'download';
        case StepActionType.EditCompanyData:
            return 'edit';
        case StepActionType.ViewCompanyData:
            return 'visibility';
        case StepActionType.SignContractLink:
            return 'draw';
        case StepActionType.DownloadContract:
            return 'download';
        default:
            return '';
    }
};

let alreadyPolling = false;

export const StepAction = ({ stepAction, stepProgress }: Props) => {
    const { t } = useTranslation('translations', { keyPrefix: 'steps.actions' });
    const [opened, { open, close }] = useDisclosure(false);
    const [searchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const { requestId = '' } = useParams();
    const [isLoading, setIsLoading] = useState(false);

    const event = searchParams.get('event');

    const inPolling =
        event === 'signing_complete' &&
        stepProgress === StepProgress.InProgress &&
        stepAction.actionType === StepActionType.SignContractLink;

    if (inPolling && !alreadyPolling) {
        alreadyPolling = true;
        sendTrackingEvent(TrackingEvents.ContractSigned);

        setInterval(() => {
            queryClient.invalidateQueries({ queryKey: getGetStepsQueryKey(requestId) });
            alreadyPolling = false;
        }, POLLING_INTERVAL);
    }

    const handleDownloadContract = () => {
        setIsLoading(true);
        downloadFile(welcomeApiClient, getDownloadContractFilesZipContentQueryKey(requestId).join(''), 'contract.zip')
            .catch((err) => {
                toastNotifications.error({
                    title: t('errorDownloadingContract'),
                    message: getExceptionDetails(err)?.message
                });
            })
            .finally(() => setIsLoading(false));
    };

    const handleActionClick = ({ actionType }: StepActionModel) => {
        switch (actionType) {
            case StepActionType.FileUpload:
                // TODO implement behaviour
                break;
            case StepActionType.FileDownload:
                // TODO implement behaviour
                break;
            case StepActionType.EditCompanyData:
                sendTrackingEvent(TrackingEvents.DataInputStarted);
                open();
                break;
            case StepActionType.ViewCompanyData:
                open();
                break;
            case StepActionType.SignContractLink:
                sendTrackingEvent(TrackingEvents.ContractSigned);
                open();
                break;
            case StepActionType.DownloadContract:
                handleDownloadContract();
                break;
            default:
        }
    };

    return (
        <>
            <Timeline.Action
                icon={getActionIcon(stepAction.actionType)}
                loading={inPolling || isLoading}
                title={t(stepAction.actionType)}
                onClick={() => handleActionClick(stepAction)}
            />
            <StepActionModal open={opened} stepActonType={stepAction.actionType} onClose={close} />
        </>
    );
};
