/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  AcceptInvitationParams,
  CreateInvitationExistingModel,
  CreateInvitationNewModel,
  GetOnboardingRequestByIdResponse,
  GetOnboardingRequestDetailResponse,
  ProblemDetails
} from '../../models'
import { customMutator } from '../../customMutator';
import type { ErrorType } from '../../customMutator';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Creates an invitation for a new customer
 */
export const createInvitationForNewCustomer = (
    createInvitationNewModel: CreateInvitationNewModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetOnboardingRequestByIdResponse>(
      {url: `/invitations/new`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createInvitationNewModel
    },
      options);
    }
  


export const getCreateInvitationForNewCustomerMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvitationForNewCustomer>>, TError,{data: CreateInvitationNewModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createInvitationForNewCustomer>>, TError,{data: CreateInvitationNewModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInvitationForNewCustomer>>, {data: CreateInvitationNewModel}> = (props) => {
          const {data} = props ?? {};

          return  createInvitationForNewCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateInvitationForNewCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof createInvitationForNewCustomer>>>
    export type CreateInvitationForNewCustomerMutationBody = CreateInvitationNewModel
    export type CreateInvitationForNewCustomerMutationError = ErrorType<ProblemDetails>

    export const useCreateInvitationForNewCustomer = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvitationForNewCustomer>>, TError,{data: CreateInvitationNewModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createInvitationForNewCustomer>>,
        TError,
        {data: CreateInvitationNewModel},
        TContext
      > => {

      const mutationOptions = getCreateInvitationForNewCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Creates an invitation for an existing customer
 */
export const createInvitationForExistingCustomer = (
    createInvitationExistingModel: CreateInvitationExistingModel,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetOnboardingRequestByIdResponse>(
      {url: `/invitations/existing`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createInvitationExistingModel
    },
      options);
    }
  


export const getCreateInvitationForExistingCustomerMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvitationForExistingCustomer>>, TError,{data: CreateInvitationExistingModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof createInvitationForExistingCustomer>>, TError,{data: CreateInvitationExistingModel}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createInvitationForExistingCustomer>>, {data: CreateInvitationExistingModel}> = (props) => {
          const {data} = props ?? {};

          return  createInvitationForExistingCustomer(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateInvitationForExistingCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof createInvitationForExistingCustomer>>>
    export type CreateInvitationForExistingCustomerMutationBody = CreateInvitationExistingModel
    export type CreateInvitationForExistingCustomerMutationError = ErrorType<ProblemDetails>

    export const useCreateInvitationForExistingCustomer = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createInvitationForExistingCustomer>>, TError,{data: CreateInvitationExistingModel}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof createInvitationForExistingCustomer>>,
        TError,
        {data: CreateInvitationExistingModel},
        TContext
      > => {

      const mutationOptions = getCreateInvitationForExistingCustomerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Accepts invitation and returns request details
 */
export const acceptInvitation = (
    id: string,
    params: AcceptInvitationParams,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<GetOnboardingRequestDetailResponse>(
      {url: `/invitations/${id}/accept`, method: 'POST',
        params
    },
      options);
    }
  


export const getAcceptInvitationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptInvitation>>, TError,{id: string;params: AcceptInvitationParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof acceptInvitation>>, TError,{id: string;params: AcceptInvitationParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptInvitation>>, {id: string;params: AcceptInvitationParams}> = (props) => {
          const {id,params} = props ?? {};

          return  acceptInvitation(id,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AcceptInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof acceptInvitation>>>
    
    export type AcceptInvitationMutationError = ErrorType<ProblemDetails>

    export const useAcceptInvitation = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptInvitation>>, TError,{id: string;params: AcceptInvitationParams}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof acceptInvitation>>,
        TError,
        {id: string;params: AcceptInvitationParams},
        TContext
      > => {

      const mutationOptions = getAcceptInvitationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Resend the invitation mail for a request in the Invited state.
 */
export const resendInvitation = (
    id: string,
 options?: SecondParameter<typeof customMutator>,) => {
      
      
      return customMutator<void>(
      {url: `/invitations/${id}/resend`, method: 'POST'
    },
      options);
    }
  


export const getResendInvitationMutationOptions = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendInvitation>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationOptions<Awaited<ReturnType<typeof resendInvitation>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resendInvitation>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  resendInvitation(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResendInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof resendInvitation>>>
    
    export type ResendInvitationMutationError = ErrorType<ProblemDetails>

    export const useResendInvitation = <TError = ErrorType<ProblemDetails>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendInvitation>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customMutator>}
): UseMutationResult<
        Awaited<ReturnType<typeof resendInvitation>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getResendInvitationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    