/**
 * Generated by orval v7.2.0 🍺
 * Do not edit manually.
 * Doka Customer Onboarding API
 * OpenAPI spec version: 0.0
 */

export type RequestStateType = typeof RequestStateType[keyof typeof RequestStateType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestStateType = {
  Invited: 'Invited',
  DataInput: 'DataInput',
  DataVerification: 'DataVerification',
  AdditionalDataInput: 'AdditionalDataInput',
  CoreSystemActivation: 'CoreSystemActivation',
  ContractSigning: 'ContractSigning',
  SignatureVerification: 'SignatureVerification',
  ServiceActivation: 'ServiceActivation',
  Completed: 'Completed',
  Declined: 'Declined',
  Aborted: 'Aborted',
} as const;
