import { LoadingAnimation, toastNotifications, getExceptionDetails } from '@uag/react-core';
import { useTranslation } from 'react-i18next';

import { welcomeApiClient } from 'api/customMutator';
import { OnboardingRequestModel } from 'api/models';
import { getDownloadAttachmentContentQueryKey, useGetAttachments } from 'api/queries/attachments/attachments';
import { getDownloadContractFileContentQueryKey, useGetSignedContractFiles } from 'api/queries/contracts/contracts';
import { downloadFile } from 'utils';
import { RequestAttachment } from './RequestAttachment';

type Props = {
    request: OnboardingRequestModel;
};

export const RequestAttachments = ({ request }: Props) => {
    const { data: attachmentsResponse, isPending: areAttachmentsPending } = useGetAttachments(request.id ?? '');
    const { data: contractsResponse, isPending: areContractsPending } = useGetSignedContractFiles(request.id ?? '');
    const attachments = attachmentsResponse?.data ?? [];
    const contracts = contractsResponse?.data ?? [];
    const { t } = useTranslation();

    const handleContractDownload = async (attachmentId: string, attachmentName: string) => {
        try {
            await downloadFile(welcomeApiClient, getDownloadContractFileContentQueryKey(request.id, attachmentId).join(), attachmentName);
        } catch (err) {
            toastNotifications.error({ title: t('errorDownloadingContract'), message: getExceptionDetails(err)?.message ?? '' });
        }
    };

    const handleAttachmentDownload = async (attachmentId: string, attachmentName: string) => {
        try {
            await downloadFile(welcomeApiClient, getDownloadAttachmentContentQueryKey(request.id, attachmentId).join(), attachmentName);
        } catch (err) {
            toastNotifications.error({ title: t('errorDownloadingAttachment'), message: getExceptionDetails(err)?.message });
        }
    };

    return (
        <div>
            <div>{t('documents')}</div>

            <div className="grid grid-cols-2 gap-base">
                {(areAttachmentsPending || areContractsPending) && <LoadingAnimation />}
                {!areAttachmentsPending && !areContractsPending && (
                    <>
                        {attachments &&
                            attachments.map((attachment) => (
                                <RequestAttachment
                                    key={attachment.id}
                                    attachmentType={attachment.attachmentType}
                                    fileName={attachment.fileName}
                                    fileSizeBytes={attachment.fileSizeBytes}
                                    id={attachment.id}
                                    onClick={() => handleAttachmentDownload(attachment.id, attachment.fileName!)}
                                />
                            ))}
                        {contracts &&
                            contracts.map((contract) => (
                                <RequestAttachment
                                    key={contract.id}
                                    attachmentType={contract.attachmentType}
                                    fileName={contract.fileName}
                                    fileSizeBytes={contract.fileSizeBytes}
                                    id={contract.id}
                                    onClick={() => handleContractDownload(contract.id, contract.fileName!)}
                                />
                            ))}
                    </>
                )}
            </div>
        </div>
    );
};
