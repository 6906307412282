import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { sharedInstance } from '../../../i18n';
import { EInvoiceType } from 'api/models';
import { CountryRule } from './countryRules';

export const companyBaseDataSchema = z
    .object({
        companyName: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('companyName') })),
        additionalCompanyName: z.string().optional(),
        industrialSector: z
            .string()
            .min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('industrialSector') })),
        country: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('country') })),
        street: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('street') })),
        houseNumber: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('houseNumber') })),
        zipCode: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('zipCode') })),
        city: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('city') })),
        uidNumber: z.string().min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('uidNumber') })),
        useElectronicInvoice: z.boolean(),
        electronicInvoiceEmail: z.string().email(sharedInstance.t('notValidMail')).or(z.literal('')).or(z.null()),
        electronicInvoiceType: z.nativeEnum(EInvoiceType).nullish(),
        taxNumber: z.string().optional(),
        generalOfficeMail: z
            .string()
            .min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('generalOfficeMail') }))
            .email(sharedInstance.t('notValidMail')),
        phoneNumber: z
            .string()
            .min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('phoneNumber') }))
            .refine(
                (phoneNumber: string) => isValidPhoneNumber(phoneNumber),
                () => ({ message: sharedInstance.t('notValidPhoneNumber') })
            ),
        mobileNumber: z
            .string()
            .optional()
            .refine(
                (phoneNumber: string | undefined) => !phoneNumber || isValidPhoneNumber(phoneNumber),
                () => ({ message: sharedInstance.t('notValidPhoneNumber') })
            )
    })
    .superRefine(({ country, zipCode, uidNumber, electronicInvoiceEmail,electronicInvoiceType, useElectronicInvoice }, refinementContext) => {
        checkCountryRule(country, zipCode, uidNumber, refinementContext);
        checkElectronicInvoiceEmail(useElectronicInvoice, electronicInvoiceEmail,electronicInvoiceType, refinementContext);
        return refinementContext;
    });

export const requestSchema = z
    .object({
        companyData: companyBaseDataSchema,
        technicalAdministrator: z
            .string()
            .min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('technicalAdministrator') }))
            .email(sharedInstance.t('notValidMail')),
        contractSignatory: z
            .string()
            .min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('contractSignatory') }))
            .email(sharedInstance.t('notValidMail')),
        businessLicence: z.string().optional(),
        existingCustomer: z.boolean()
    })
    .superRefine(({ businessLicence, existingCustomer }, refinementContext) => {
        if (!existingCustomer && !businessLicence) {
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                message: sharedInstance.t('isRequired', { label: sharedInstance.t('businessLicence') })
            });
        }
    });

export const invitationSchema = z.object({
    companyData: companyBaseDataSchema,
    companyRegistrar: z
        .string()
        .min(1, sharedInstance.t('isRequired', { label: sharedInstance.t('companyRegistrar') }))
        .email(sharedInstance.t('notValidMail'))
});

export const companySchema = z.object({
    companyData: companyBaseDataSchema
});

export type CompanyBaseDataSchemaType = z.infer<typeof companyBaseDataSchema>;
export type CompanySchemaType = z.infer<typeof companySchema>;
export type RequestSchemaType = z.infer<typeof requestSchema>;

export type InvitationSchemaType = z.infer<typeof invitationSchema>;

function checkElectronicInvoiceEmail(
    useElectronicInvoice: boolean,
    electronicInvoiceEmail: string | null,
    electronicInvoiceType: EInvoiceType | null | undefined,
    refinementContext: z.RefinementCtx
) {
    if (useElectronicInvoice) {
        if(!electronicInvoiceEmail){
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['electronicInvoiceEmail'],
                message: sharedInstance.t('isRequired', { label: sharedInstance.t('electronicInvoiceEmail') })
            });
        }
        if(!electronicInvoiceType){
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['electronicInvoiceType'],
                message: sharedInstance.t('isRequired', { label: sharedInstance.t('electronicInvoiceType') })
            });
        }
    }
}

function checkCountryRule(country: string, zipCode: string, uidNumber: string, refinementContext: z.RefinementCtx) {
    const rule = CountryRule.All().find((rule) => rule.id === country);

    if (rule) {
        if (!rule.zipCodeRegex.test(zipCode)) {
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                message: sharedInstance.t('invalidFormat', {
                    label: sharedInstance.t('zipCode'),
                    sample: rule.zipCodeSample
                }),
                path: ['zipCode']
            });
        }
        if (!rule.taxNumberRegex.test(uidNumber)) {
            refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                message: sharedInstance.t('invalidFormat', {
                    label: sharedInstance.t('uidNumber'),
                    sample: rule.taxNumberSample
                }),
                path: ['uidNumber']
            });
        }
    }
}
