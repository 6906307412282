import { AxiosInstance } from 'axios';

export const downloadFile = async (apiClient: AxiosInstance, url: string, filename: string) => {
    const blob = await apiClient.get(url, { responseType: 'blob' });
    const windowUrl = window.URL.createObjectURL(blob.data);
    const a = document.createElement('a');
    a.href = windowUrl;
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); // afterwards we remove the element again
};
