import { useAcceptInvitation } from '@shared/api/queries/invitations/invitations';
import { ErrorView, LoadingAnimation } from '@uag/react-core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const Invitation = () => {
    const { requestId = '' } = useParams();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const { mutateAsync, status } = useAcceptInvitation();

    if (!searchParams.get('key')) {
        return <ErrorView description={t('keyMissing')} />;
    }

    if (status === 'idle') {
        mutateAsync({ id: requestId, params: { key: searchParams.get('key')! } })
            .then((response) => navigate(`/request/${response.data?.id}`))
            .catch(() => navigate('/'));
    }

    return <LoadingAnimation text={t('acceptingInvitation')} />;
};
