export const TrackingEvents = {
    NewCustomer: 'welcome-newcustomer',
    Started: 'welcome-started',
    DataInputStarted: 'welcome-datainput-started',
    DraftSaved: 'welcome-datainput-draft-saved',
    RequestSent: 'welcome-datainput-request-sended',
    ContractStarted: 'welcome-contract-started',
    ContractSigned: 'welcome-contract-signed',
    StartOnlineshop: 'welcome-start-onlineshop',
    StartMyDoka: 'welcome-start-mydoka'
};
